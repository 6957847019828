<template>
    <div>
        <div class="header">
            <div class="logo">
                <img src="@/assets/logo.jpeg" alt="分时预约系统">
            </div>
            <div class="clock">
                <div class="date">
                    {{date}}
                </div>
                <div class="time">
                    {{time}}
                </div>
            </div>
        </div>
        <div class="main">
            <table class="table" border="0" cellpadding="0" cellspacing="0">
                <tr>
                    <td style="background-color: #eee;">诊室</td>
                    <td style="background-color: #eee;">科室名称</td>
                    <td style="background-color: #eee;">正在就诊</td>
                    <td style="background-color: #eee;">等待就诊</td>
                    <td style="background-color: #eee;">出诊医生</td>
                </tr>
                <tr>
                    <td style="background-color: #fff;">2诊室</td>
                    <td style="background-color: #fff;">皮肤科</td>
                    <td style="background-color: #fff;">李宗盛</td>
                    <td style="background-color: #fff;">刘明</td>
                    <td style="background-color: #fff;">于广发</td>
                </tr>
                <tr>
                    <td style="background-color: #bee0fc;">5诊室</td>
                    <td style="background-color: #bee0fc;">中医科</td>
                    <td style="background-color: #bee0fc;">张学友</td>
                    <td style="background-color: #bee0fc;">刘德华</td>
                    <td style="background-color: #bee0fc;">于广发</td>
                </tr>
                <tr>
                    <td style="background-color: #fff;">4诊室</td>
                    <td style="background-color: #fff;">中医科</td>
                    <td style="background-color: #fff;">张学友</td>
                    <td style="background-color: #fff;">刘德华</td>
                    <td style="background-color: #fff;">于广发</td>
                </tr>
                <tr>
                    <td style="background-color: #bee0fc;">3诊室</td>
                    <td style="background-color: #bee0fc;">中医科</td>
                    <td style="background-color: #bee0fc;">张学友</td>
                    <td style="background-color: #bee0fc;">刘德华</td>
                    <td style="background-color: #bee0fc;">于广发</td>
                </tr>
                <tr>
                    <td style="background-color: #fff;">5诊室</td>
                    <td style="background-color: #fff;">中医科</td>
                    <td style="background-color: #fff;">张学友</td>
                    <td style="background-color: #fff;">刘德华</td>
                    <td style="background-color: #fff;">于广发</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                date:'',
                time:''
            }
        },
        methods: {
            now() {
                setInterval(()=>{
                    let n = new Date();
                    this.date = n.format("yyyy年MM月dd日")
                    this.time = n.format("hh:mm:ss")
                }, 1000)
            }
        },
        mounted() {
            this.now()
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
        padding: 20px;
        height: 100px;
    }

    .logo {
        flex: 1
    }

    .clock {
        text-align: right;
    }

    .main {
        background-color: #4f9fe7;
        position: absolute;
        top: 100px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        border-radius: 15px;
        padding: 20px;
    }

    .table {
        background-color: white;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        font-size: 56px;
        text-align: center;
    }
    .table td{
        padding:10px;

    }
</style>
